import { useQuery } from "@apollo/client"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import React from "react"
import { RouteChildrenProps } from "react-router"
import { AdminAllSegmentsQuery } from "../../../__generated__/AdminAllSegmentsQuery"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_ALL_SEGMENTS_QUERY } from "../queries"

function Analytics({ location, history }: RouteChildrenProps) {
  const segmentsQuery = useQuery<AdminAllSegmentsQuery>(ADMIN_ALL_SEGMENTS_QUERY, {})
  const segments = segmentsQuery.data?.allSegments
  // const classes = useStyles();
  // const segmentId = getParam(searchKey, location.search);
  // const value = segmentId && segments && segments.findIndex((s) => s.segmentId === segmentId) || 0;
  // function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
  //   const segment = segments && segments.find((k, i) => i === newValue);
  //   if (segment) {
  //     history.replace({...location, ...{search: setParam(location.search, searchKey, segment.segmentId)}});
  //   }
  // }
  // console.dir(segments)
  const [state, setState] = React.useState({
    seasonIds: [] as string[],
    showSpinner: false,
  })
  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let seasonIds = state.seasonIds.concat([name])
    if (!event.target.checked) {
      seasonIds = seasonIds.filter((n) => n !== name)
    }
    setState({ ...state, ...{ seasonIds } })
  }
  const handleSubmit = () => {
    setState({ ...state, ...{ showSpinner: true } })
  }
  return (
    <Layout title={`Marketing - Segment`}>
      {(segments &&
        segments.map((segment) => {
          const { id, gameInstance, season, year, productAbbrev, masterProductId } = segment.season
          const { description } = gameInstance
          return (
            <FormGroup key={segment.id} row={true}>
              <FormControlLabel
                control={<Checkbox checked={state.seasonIds.includes(id)} onChange={handleChange(id)} value={id} />}
                color={"primary"}
                title={`${description} - ${year} ${season} Season`}
                label={`${year} ${productAbbrev} (MPID: ${masterProductId})`}
              />
            </FormGroup>
          )
        })) || <LoadingView />}
      <br />
      {state.showSpinner ? (
        <LoadingView />
      ) : (
        <>
          <Button
            component="a"
            variant="contained"
            color={"primary"}
            onClick={handleSubmit}
            disabled={!state.seasonIds.length}
            href={`/admin/marketing/entries-cust-ids.csv?seasonIds=${state.seasonIds.join(",").replace(/\=/g, "")}`}
          >
            Export CustIds
          </Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Button
            component="a"
            variant="contained"
            color={"primary"}
            onClick={handleSubmit}
            disabled={!state.seasonIds.length}
            href={`/admin/marketing/entries-export.csv?seasonIds=${state.seasonIds.join(",").replace(/\=/g, "")}`}
          >
            Export Entries
          </Button>
        </>
      )}
    </Layout>
  )
}

export default Analytics
